import imgAlan from "../assets/image/jpg/samurai-rashie.jpg";

export const alanWorks = [
  {
    brand: `Afro Samurai`,
    slug: `alan`,
    title: `Afro Samurai Rashie`,
    categories: ["rashguard"],
    thumbnail: imgAlan,
  link : "https://shop.10thplanetsm.com/products/afro-samurai-alan-sanchez-rash-guard"
},
];
