import imgAlan from "../assets/image/png/alan-folded.png";
import imgTony from "../assets/image/png/arnal2.png";
import imgTonyTheTiger from "../assets/image/png/tonythetiger.png";
import imgIan from "../assets/image/png/ian-transparent.png";
import imgMiles from "../assets/image/png/miles.png";

export const coaches = [
  {
    brand: `Alan Sanchez`,
    slug: `alan`,
    title: `Alan Sanchez`,
    categories: ["jiu-jitsu"],
    thumbnail: imgAlan,
  link : "/alan"
},
  {
    brand: `Miles Robinson`,
    slug: `miles`,
    title: `Miles Robinson`,
    categories: ["strength"],
    thumbnail: imgMiles,
  link : "/miles"
},
  /*
  {
    brand: `Tony Arnal`,
    slug: `tony`,
    title: `Tony Arnal.`,
    categories: ["jiu-jitsu"],
    thumbnail: imgTony,
  link : "/tony"
},
  */
  {
    brand: `Tony Sangimino`,
    slug: `tonythetiger`,
    title: `Tony Sangimino`,
    categories: ["judo", "takedowns"],
    thumbnail: imgTonyTheTiger,
  link : "/tonythetiger"
},
  {
    brand: `Ian Sulcer`,
    slug: `ian`,
    title: `Ian Sulcer`,
    categories: ["jiu-jitsu", "takedowns"],
    thumbnail: imgIan,
  link : "/ian"
},
];
